import { render, staticRenderFns } from "./flash-main-contacts.vue?vue&type=template&id=281ea076&scoped=true&"
import script from "./flash-main-contacts.vue?vue&type=script&lang=js&"
export * from "./flash-main-contacts.vue?vue&type=script&lang=js&"
import style0 from "./flash-main-contacts.vue?vue&type=style&index=0&id=281ea076&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281ea076",
  null
  
)

export default component.exports
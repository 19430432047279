<template>
  <div class="flash-burger-menu" v-show="value">
    <div class="close-button" @click="$emit('close')">
      <div class="line1" />
      <div class="line2" />
    </div>

    <nav>
      <a href="#specialist" @click="handleAnchorClick"><h1>СПЕЦИАЛИСТ</h1></a>

      <a href="#price" @click="handleAnchorClick"><h1>ПРАЙС</h1></a>

      <a href="#equipment" @click="handleAnchorClick"><h1>АППАРАТ</h1></a>

      <a href="#faq" @click="handleAnchorClick"><h1>ОТВЕТЫ НА ВОПРОСЫ</h1></a>

      <a href="#contacts" @click="handleAnchorClick"><h1>КОНТАКТЫ</h1></a>
    </nav>

    <div class="contacts">
      <div class="whatsapp-logo" @click="handleButtonClick" />

      <a href="tel:+79197447124"><h1>+7 919 744-71-24</h1></a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "flash-burger-menu",

  props: {
    value: {
      type: Boolean,
      default() {
        return false
      },
    },
  },

  data() {
    return {
      whatsappNumber: '+79197447124',
    }
  },

  methods: {
    handleButtonClick() {
      const url = `//api.whatsapp.com/send?phone=${ this.whatsappNumber }&text=${ this.getWelcomeText() }!%20На%20какое%20время%20есть%20ближайшая%20запись?`

      window.open(url, '_blank').focus();
    },

    getCurrentHours() {
      return new Date().getUTCHours() + 3
    },

    getWelcomeText() {
      const hours = this.getCurrentHours()

      if (hours >= 5 && hours < 12) {
        return "Доброе утро"
      }

      if (hours >= 12 && hours < 17) {
        return "Добрый день"
      }

      if (hours >= 17 && hours < 22) {
        return "Добрый вечер"
      }

      return "Доброй ночи"
    },

    handleAnchorClick() {
      this.$emit('close')
    },
  },
})
</script>

<style scoped lang="sass">
.flash-burger-menu
  width: 100vw
  height: 100vh
  background-color: rgba(83, 11, 35, 0.95)
  position: fixed
  top: 0
  left: 0
  padding: 20px
  display: flex
  flex-direction: column
  align-items: flex-end
  gap: 50px

  @media (min-width: $desktopScreenMinWidth)
    display: none

  .close-button
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 54px
    position: relative

    &:hover
      cursor: pointer

    > *
      width: 24px
      height: 2px
      background-color: white
      position: absolute
      +border-radius(999px)

      &.line1
        transform: rotate(-45deg)

      &.line2
        transform: rotate(45deg)

  nav
    display: flex
    flex-direction: column
    gap: 20px
    text-align: right
    color: white
    font-weight: 400
    font-size: 20px
    line-height: 120%
    padding-right: 10px

  .contacts
    display: flex
    align-items: center
    gap: 12px
    font-weight: 400
    line-height: 120%
    color: white
    font-size: 24px

    > *
      cursor: pointer

    .whatsapp-logo
      +background-image-settings()
      background-image: url("/src/assets/images/svg-icons/whatsapp.svg")
      width: 30px
      height: 30px
</style>
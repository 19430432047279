<template>
  <div class="flash-main-contacts">
    <flash-box-shadow class="box-shadow" />

    <div class="info">
      <h5 class="title">КОНТАКТЫ</h5>

      <main>
        <ul class="info">
          <li
              v-for="(contact, index) in contacts"
              :key="`flash-main-contacts-${ index }`"
          >
            <h1 class="title" v-html="contact.title" />

            <h1 class="content" v-html="contact.content" />
          </li>
        </ul>

        <flash-button class="button" title="НАПИСАТЬ В WHATSAPP" src-icon="/assets/images/svg-icons/whatsapp.svg" />
      </main>
    </div>

    <div class="map">
      <yandex-map
          :settings="settings"
          :coords="[55.772249, 37.669648]"
          :zoom="17"
          :ymap-class="'ymap'"
          :controls="[]"
      >
        <ymap-marker
            marker-id="flash-main-contacts-ymapmarker-1"
            :coords="[55.772249, 37.669648]"
            :icon="markerIcon"
        >
        </ymap-marker>
      </yandex-map>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import flashButton from "@/components/UI-kit/button/flash-button.vue";
import flashBoxShadow from "@/components/UI-kit/box-shadow/flash-box-shadow.vue";

export default defineComponent({
  name: "flash-main-contacts",

  components: {
    flashButton,
    flashBoxShadow,
  },

  data() {
    return {
      settings: {
        apiKey: 'cb42ebe3-f9f5-4c76-94e0-4ac8742524e1',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },

      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: '/assets/images/svg-icons/YmapMarker.svg',
        imageSize: [40, 40],
        imageOffset: [-20, -20],
        contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
      },

      contacts: [
        {
          title: "Адрес",
          content: "г. Москва<br>Ул. Новорязанская 29 стр 1",
        },

        {
          title: "Режим работы",
          content: "Ежедневно 10:00 - 22:00",
        },

        {
          title: "Контактный телефон",
          content: "8 919 744 71 24",
        },
      ],
    }
  },
})
</script>

<style scoped lang="sass">
.flash-main-contacts
  display: flex
  position: relative

  @media (min-width: $desktopScreenMinWidth)
    gap: 123px
    width: 1216px
    margin-bottom: 130px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 40px
    align-items: center
    width: 100%
    flex-direction: column

  .box-shadow
    position: absolute

    @media (min-width: $desktopScreenMinWidth)
      right: 250px
      bottom: 50px

    @media (max-width: $mobileScreenMaxWidth)
      bottom: 50%
      left: 40%

  .info
    display: flex
    flex-direction: column

    @media (min-width: $desktopScreenMinWidth)
      gap: 79px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 40px
      width: 100%

    > .title
      font-weight: 400
      font-size: 48px
      letter-spacing: 1.44px
      line-height: 100%
      color: $deepBordo

      @media (min-width: $desktopScreenMinWidth)

      @media (max-width: $mobileScreenMaxWidth)
        text-align: center

    main
      display: flex
      flex-direction: column
      gap: 40px

      .info
        display: flex
        flex-direction: column

        @media (min-width: $desktopScreenMinWidth)
          gap: 30px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 20px

        li
          display: flex
          flex-direction: column
          font-weight: 400
          font-size: 20px

          @media (min-width: $desktopScreenMinWidth)
            gap: 12px

          @media (max-width: $mobileScreenMaxWidth)
            gap: 8px

          > .title
            color: $blurBordo
            line-height: 120%

          .content
            color: $darkBordo
            line-height: 140%

      .button
        @media (min-width: $desktopScreenMinWidth)
          position: relative
          left: -10px

        @media (max-width: $mobileScreenMaxWidth)

  .image
    +background-image-settings()

    @media (min-width: $desktopScreenMinWidth)
      width: 759px
      height: 488px
      +border-radius(400px)
      background-image: url("/src/assets/images/templates/main/contacts-ds.png")

    @media (max-width: $mobileScreenMaxWidth)
      width: calc(100% + 40px)
      padding-top: calc((100% + 40px) / 360 * 488)
      background-image: url("/src/assets/images/templates/main/contacts-mb.png")

  .map
    @media (min-width: $desktopScreenMinWidth)
      width: 759px
      height: 488px
      +border-radius(400px)
      overflow: hidden

    @media (max-width: $mobileScreenMaxWidth)
      width: calc(100% + 40px)
      height: 488px

    > *
      @media (min-width: $desktopScreenMinWidth)
        width: 100%
        height: calc(100% + 30px)

      @media (max-width: $mobileScreenMaxWidth)
        width: 100%
        height: 100%

      > *
        width: 100%
        height: 100%

    .ymap
      width: 100%
      height: 100%
</style>
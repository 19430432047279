<template>
  <div id="app">
    <flash-header @open-burger="isShownBurger = true" />

    <flash-main />

    <flash-footer />

    <flash-burger-menu v-model="isShownBurger" @close="isShownBurger = false" />
  </div>
</template>

<script>
import flashHeader from "@/components/UI-kit/header/flash-header.vue";
import flashMain from "@/components/templates/main/flash-main.vue";
import flashFooter from "@/components/UI-kit/footer/flash-footer.vue";
import flashBurgerMenu from "@/components/UI-kit/burger-menu/flash-burger-menu.vue";

export default {
  name: 'App',

  components: {
    flashHeader,
    flashMain,
    flashFooter,
    flashBurgerMenu,
  },

  data() {
    return {
      isShownBurger: false,
    }
  },
}
</script>

<style lang="sass">
#app
  display: flex
  flex-direction: column
  max-width: 100vw
  width: 100%
  min-height: 100vh
</style>

import Vue from 'vue'
import App from './App.vue'

import '/src/assets/styles/style.css'

Vue.config.productionTip = false

const settings = {
  apiKey: 'cb42ebe3-f9f5-4c76-94e0-4ac8742524e1',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}

import YmapPlugin from 'vue-yandex-maps'
import { ymapMarker } from "vue-yandex-maps";

Vue.use(YmapPlugin, settings)
Vue.use(ymapMarker)

new Vue({
  render: h => h(App),
}).$mount('#app')

<template>
  <div class="flash-header">
    <div class="logo-wrapper">
      <div class="logo" />

      <h3>Flash</h3>
    </div>

    <nav class="only-ds">
      <a href="#specialist"><h1>СПЕЦИАЛИСТ</h1></a>

      <a href="#price"><h1>ПРАЙС</h1></a>

      <a href="#equipment"><h1>АППАРАТ</h1></a>

      <a href="#faq"><h1>ОТВЕТЫ НА ВОПРОСЫ</h1></a>

      <a href="#contacts"><h1>КОНТАКТЫ</h1></a>
    </nav>

    <div class="contacts">
      <div class="whatsapp-logo only-ds" @click="handleButtonClick" />

      <a href="tel:+79197447124"><h1>+7 919 744-71-24</h1></a>
    </div>

    <flash-burger class="only-mb" @click="$emit('open-burger')"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import flashBurger from "@/components/UI-kit/burger/flash-burger.vue";

export default defineComponent({
  name: "flash-header",

  components: {
    flashBurger,
  },

  data() {
    return {
      whatsappNumber: '+79197447124',
    }
  },

  methods: {
    handleButtonClick() {
      const url = `//api.whatsapp.com/send?phone=${ this.whatsappNumber }&text=${ this.getWelcomeText() }!%20На%20какое%20время%20есть%20ближайшая%20запись?`

      window.open(url, '_blank').focus();
    },

    getCurrentHours() {
      return new Date().getUTCHours() + 3
    },

    getWelcomeText() {
      const hours = this.getCurrentHours()

      if (hours >= 5 && hours < 12) {
        return "Доброе утро"
      }

      if (hours >= 12 && hours < 17) {
        return "Добрый день"
      }

      if (hours >= 17 && hours < 22) {
        return "Добрый вечер"
      }

      return "Доброй ночи"
    },
  },
})
</script>

<style scoped lang="sass">
.flash-header
  display: flex
  align-items: center
  width: 100%

  @media (min-width: $desktopScreenMinWidth)
    gap: 113px
    padding: 30px 0 80px
    justify-content: center

  @media (max-width: $mobileScreenMaxWidth)
    gap: 30px
    width: 100%
    justify-content: space-between
    padding: 20px 20px 32px

  .logo-wrapper
    display: flex
    flex-direction: column
    gap: 4px
    align-items: center
    font-weight: 400
    font-size: 10px
    line-height: 100%
    color: $deepBordo

    .logo
      width: 41px
      height: 40px
      +background-image-settings()
      background-image: url("/src/assets/images/svg-icons/logo.svg")

  nav
    display: flex
    align-items: center
    gap: 30px
    font-weight: 400
    font-size: 20px
    line-height: 100%
    color: $deepBordo

  .contacts
    display: flex
    align-items: center
    gap: 12px
    font-weight: 400
    line-height: 100%
    color: $darkBordo

    @media (min-width: $desktopScreenMinWidth)
      font-size: 20px

    @media (max-width: $mobileScreenMaxWidth)
      font-size: 17px

    > *
      cursor: pointer

    .whatsapp-logo
      +background-image-settings()
      background-image: url("/src/assets/images/svg-icons/whatsapp.svg")
      width: 30px
      height: 30px
</style>
<template>
  <div class="flash-main-specialist">
    <flash-box-shadow class="box-shadow" />

    <h5 class="title">СПЕЦИАЛИСТЫ</h5>

    <aside>
      <div class="left">
        <div class="image" />

        <div class="content only-ds">
          <div class="text">
            <h1>Мастер Оксана</h1>

            <h1>- Косметолог</h1>

            <h1>- Специалист с медицинским образованием</h1>

            <h1>- Профессионал в сфере лазерной эпиляции</h1>
          </div>

          <flash-button class="button" title="БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ" />
        </div>
      </div>

      <div class="right">
        <div class="info only-ds">
          <h1>Мастер Олеся</h1>

          <h1>Опытный специалист с многолетним  стажем в области лазерной эпиляции</h1>
        </div>

        <div class="info only-mb">
          <h1>Мастер Оксана</h1>

          <h1>- Косметолог</h1>

          <h1>- Специалист с медицинским образованием</h1>

          <h1>- Профессионал в сфере лазерной эпиляции</h1>
        </div>

        <div class="image" />

        <div class="content only-mb">
          <div class="text">
            <h1>Мастер Олеся</h1>

            <h1>Опытный специалист с многолетним  стажем в области лазерной эпиляции</h1>
          </div>

          <flash-button title="БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ" />
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import flashButton from "@/components/UI-kit/button/flash-button.vue";
import flashBoxShadow from "@/components/UI-kit/box-shadow/flash-box-shadow.vue";

export default defineComponent({
  name: "flash-main-specialist",

  components: {
    flashButton,
    flashBoxShadow,
  },
})
</script>

<style scoped lang="sass">
.flash-main-specialist
  display: flex
  flex-direction: column
  color: $deepBordo
  position: relative
  width: 100%

  @media (min-width: $desktopScreenMinWidth)
    gap: 80px
    width: 1216px
    margin-bottom: 160px

  @media (max-width: $mobileScreenMaxWidth)
    gap: 30px
    align-items: center
    margin-bottom: 80px

  .box-shadow
    position: absolute

    @media (min-width: $desktopScreenMinWidth)
      left: -390px
      bottom: -260px

    @media (max-width: $mobileScreenMaxWidth)
      bottom: 50%
      left: -100px

  > .title
    font-weight: 400
    font-size: 48px
    line-height: 100%
    letter-spacing: 1.44px

  aside
    display: flex
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      gap: 80px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 30px
      flex-direction: column

    .left
      display: flex
      gap: 80px
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        width: 50%

      .image
        +background-image-settings()
        +border-radius(12px)
        background-image: url("/src/assets/images/templates/main/specialist1.jpg")
        aspect-ratio: 1065 / 1280

        @media (min-width: $desktopScreenMinWidth)
          width: 100%

        @media (max-width: $mobileScreenMaxWidth)
          width: 100%

      .content
        display: flex
        flex-direction: column
        gap: 40px

        .text
          display: flex
          flex-direction: column
          font-weight: 400
          font-size: 20px
          line-height: 140%
          gap: 10px

        .button
          @media (min-width: $desktopScreenMinWidth)
            position: relative
            left: -14px

          @media (max-width: $mobileScreenMaxWidth)

    .right
      display: flex
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        gap: 80px
        width: 50%

      @media (max-width: $mobileScreenMaxWidth)
        gap: 30px

      .info
        display: flex
        flex-direction: column
        font-weight: 400
        line-height: 140%

        @media (min-width: $desktopScreenMinWidth)
          gap: 40px
          font-size: 20px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 10px
          font-weight: 400
          font-size: 20px
          line-height: 140%

        .statistics
          display: flex

          @media (min-width: $desktopScreenMinWidth)
            gap: 40px
            justify-content: space-between

          @media (max-width: $mobileScreenMaxWidth)
            gap: 30px
            flex-direction: column
            max-width: 229px

          .statistic
            display: flex
            flex-direction: column
            gap: 16px
            text-align: center

            .top
              font-weight: 400
              font-size: 60px
              color: $lightBordo
              line-height: 100%

            .bottom
              font-size: 20px

      .image
        +background-image-settings()
        background-image: url("/src/assets/images/templates/main/specialist2.jpg")
        aspect-ratio: 1008 / 1280
        +border-radius(12px)

        @media (min-width: $desktopScreenMinWidth)


        @media (max-width: $mobileScreenMaxWidth)

      .content
        display: flex
        flex-direction: column
        gap: 30px

        .text
          display: flex
          flex-direction: column
          padding-left: 14px
          font-weight: 400
          font-size: 20px
          line-height: 140%
          gap: 10px
</style>